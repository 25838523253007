import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { PreviewBox } from '../Common/PreviewBox';
import { PreviewField } from '../Common/PreviewField';
import { EstadoAutorizacion } from './EstadoAutorizacion';

const formatNumber = (value = 0) => {
  const formatter = new Intl.NumberFormat('es-ES');
  const result = formatter.format(value);
  return result;
};

const formatDate = (value = null) => {
  if (!value) {
    return;
  }
  const date = new Date(value);
  const result = new Intl.DateTimeFormat('es-AR').format(date);
  return result;
};

export const VerAutorizacion = () => {
  const history = useHistory();
  const { autorizacion } = history?.location?.state || {};
  const pedidosMedicos = autorizacion?.documentacion?.filter((i) => i.codigoDocumentacion === '1');
  const resultadosEstudios = autorizacion?.documentacion?.filter(
    (i) => i.codigoDocumentacion === '5',
  );

  return (
    <Grid container spacing={2}>
      <ButtonBack />
      <Grid item xs={12}>
        <Card sx={{ paddingX: { xs: 0, sm: 1 } }}>
          <CardContent>
            <Grid container spacing={1} rowSpacing={1} gap={1}>
              <Grid item>
                <Typography variant='h5' component={'span'}>
                  Consulta de Solicitud de Autorización
                </Typography>
              </Grid>
              {autorizacion?.estado?.codigoEstado && (
                <Grid item xs={12}>
                  <EstadoAutorizacion estadoSolicitud={autorizacion.estado} />
                </Grid>
              )}
              <Grid item xs={12}>
                <PreviewBox title='Solicitud de Autorización'>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='id'
                      label='Número'
                      value={autorizacion.solicitudCobertura.idSolicitudCoberturaFormateado}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='tipoTramite'
                      label='Tipo de Trámite'
                      value={autorizacion.tipoCobertura.descripcion}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='fechaDeSolicitud'
                      label='Fecha de Solicitud'
                      value={formatDate(autorizacion.solicitudCobertura.fechaGenerada) || '-'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='estadoActual'
                      label='Estado'
                      value={autorizacion.estado.descripcionEstadoSolicitudCobertura}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='providencia'
                      label='Providencia'
                      value={formatNumber(autorizacion.estado.idHistorialEstadoSolicitudCobertura)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <PreviewField
                      id='fechaInicio'
                      label='Inicio Vigencia'
                      value={formatDate(autorizacion.solicitudCobertura.fechaIniciada) || '-'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <PreviewField
                      id='fechaVencimiento'
                      label='Vencimiento'
                      value={formatDate(autorizacion.solicitudCobertura.vencimiento) || '-'}
                    />
                  </Grid>
                </PreviewBox>
              </Grid>
              <Grid item xs={12}>
                <PreviewBox title='Documentación Presentada'>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='pedidosMedicos'
                      label='Pedidos Médicos (RP)'
                      value={pedidosMedicos.length > 0 ? pedidosMedicos.length : '-'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='resultadosEstudios'
                      label='Estudios Previos (opcional)'
                      value={resultadosEstudios.length > 0 ? resultadosEstudios.length : '-'}
                    />
                  </Grid>
                </PreviewBox>
              </Grid>
              <Grid item xs={12}>
                <PreviewBox title='Afiliado'>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='dni'
                      label='Documento'
                      value={`${autorizacion.afiliado.numeroDocumentoFormateado}`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PreviewField
                      id='nya'
                      label='Apellido y Nombre'
                      value={`${autorizacion.afiliado.apellidoNombreFormateado}`}
                    />
                  </Grid>
                </PreviewBox>
              </Grid>
              {autorizacion.practicas && autorizacion.practicas.length > 0 && (
                <Grid item xs={12}>
                  <PreviewBox title='Prácticas'>
                    {autorizacion.practicas?.map((item, idx) => {
                      const cols =
                        autorizacion.practicas.length === 1
                          ? 12
                          : autorizacion.practicas.length === 2
                          ? 6
                          : 4;
                      return (
                        <Grid item xs={12} md={cols} key={idx}>
                          <PreviewField
                            id='rp1'
                            label={`RP${idx + 1}`}
                            value={item?.trim()}
                            multiline
                            maxRows={4}
                          />
                        </Grid>
                      );
                    })}
                  </PreviewBox>
                </Grid>
              )}
              <Grid item xs={12}>
                <PreviewBox title={'Observaciones'}>
                  <Grid item xs={12}>
                    <PreviewField
                      id='observaciones'
                      label={''}
                      value={
                        autorizacion?.estado?.observacion
                          ? `${autorizacion.estado.observacion[0].toUpperCase()}${autorizacion.estado.observacion.slice(
                              1,
                            )}`
                          : '-'
                      }
                      multiline
                    />
                  </Grid>
                </PreviewBox>
              </Grid>
              {/* <Grid item xs={12}>
                <PreviewBox title='Pedidos Médicos'>
                  {pedidosMedicos?.map((item, idx) => {
                    const cols =
                      pedidosMedicos.length === 1 ? 12 : pedidosMedicos.length === 2 ? 6 : 4;
                    return (
                      <Grid item xs={12} md={cols} key={idx}>
                        <PreviewFileField
                          id='rp1'
                          label={`Archivo Adjunto ${idx + 1}`}
                          href={item.link}
                          path={item.path}
                        />
                      </Grid>
                    );
                  })}
                </PreviewBox>
              </Grid>
              {resultadosEstudios && resultadosEstudios.length > 0 && (
                <Grid item xs={12}>
                  <PreviewBox title='Resultados de Estudios'>
                    {resultadosEstudios?.map((item, idx) => {
                      const cols =
                        resultadosEstudios.length === 1
                          ? 12
                          : resultadosEstudios.length === 2
                          ? 6
                          : 4;
                      return (
                        <Grid item xs={12} md={cols} key={idx}>
                          <PreviewFileField
                            id='rp1'
                            label={`Archivo Adjunto ${idx + 1}`}
                            href={item.path}
                          />
                        </Grid>
                      );
                    })}
                  </PreviewBox>
                </Grid>
              )} */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
