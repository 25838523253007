import HighlightOff from '@mui/icons-material/HighlightOff';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Alert,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import QrPreview from '../Common/QrPreview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AutorizacionPreview = (props) => {
  const { handlerCerrarPreview } = props;

  const handleClose = () => {
    if (props.setOpen) {
      props.setOpen(false);
    }
    if (handlerCerrarPreview) {
      handlerCerrarPreview();
    }
  };

  return (
    props.open && (
      <Card sx={{ width: '100%', p: 1 }}>
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='felx-end'
              spacing={1}
            >
              <Grid item>
                <Typography variant='h6'>
                  Solicitud de Cobertura Generada{' '}
                  <CheckCircleIcon fontSize='small' color='primary'></CheckCircleIcon>
                </Typography>
              </Grid>
            </Grid>
          </Typography>
          <Grid item xs={12}>
            {props.datosAutorizacion && (
              <QrPreview
                showDownload={props.showDownload}
                showPrint={props.showPrint}
                urlReporte={props.urlReporte}
                nombreReporte={'Solicitud_cobertura_' + props.datosAutorizacion.afiliado.apellido}
                qrValue={JSON.stringify({
                  id: props.datosAutorizacion.solicitudCobertura.idSolicitudCobertura,
                })}
              />
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {props.datosAutorizacion && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='provincia'
                    variant='standard'
                    label='Nro. Solicitud'
                    defaultValue={
                      props.datosAutorizacion.solicitudCobertura.idSolicitudCoberturaFormateado
                    }
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              {props.datosAutorizacion && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='fdesde'
                    variant='standard'
                    label='Afiliado'
                    defaultValue={props.datosAutorizacion.afiliado.apellidoNombreFormateado}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              {props.datosAutorizacion && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='fhasta'
                    variant='standard'
                    label='Estado'
                    defaultValue={`${
                      props.datosAutorizacion.estado.descripcionEstadoSolicitudCobertura || ''
                    } (${props.datosAutorizacion.estado.fechaDesde || ''})`}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              {props.datosAutorizacion && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <TextField
                    id='fhasta'
                    variant='standard'
                    label='Auditoria Destino'
                    defaultValue={props.datosAutorizacion.tipoCobertura.division}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              {props.datosAutorizacion && (
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <List>
                    <Typography variant='caption' color='primary'>
                      Documentación presentada
                    </Typography>
                    {props.datosAutorizacion.documentacion?.map((d) => {
                      return (
                        <ListItem key={d.idHistorialDocumentacionSolicitudCobertura}>
                          <TextField
                            id='idafiliado'
                            variant='standard'
                            defaultValue={d.documentacion + '/' + d.fojas}
                            size='small'
                            fullWidth
                            InputProps={{
                              readOnly: true,
                              style: { fontWeight: 'bold' },
                            }}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container direction='column' justifyContent='space-between'>
            <Grid item>
              <Alert severity='info'>
                <b style={{ color: '#1d1e1eb0' }}>
                  La auditoría de la solicitud tiene hasta un máximo de 48 hs. hábiles para
                  resolverse.
                </b>
              </Alert>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            size='small'
            startIcon={<HighlightOff />}
            variant='contained'
            color='primary'
            onClick={(e) => handleClose(e)}
          >
            Cerrar
          </Button>
        </CardActions>
      </Card>
    )
  );
};

export default AutorizacionPreview;
